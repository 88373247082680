.carousel-caption {
  top: 35%;
}
.carousel-caption h5 {
  color: white;
  font-weight: 900;
}

.carousel-caption p {
  color: white;
  font-weight: 900;
}